<template>
  <div class="market-page container">
    <market-header tab-base-url="/shop-goods/goods" />
    <market-extras />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import MarketExtras from './market-extras'
import SvgIcon from '@/components/SvgIcon'
import MarketHeader from '@/views/shopGoods/market-header'
import * as API_order from '@/api/order'
import * as API_goods from "@/api/goods";
export default {
  name: 'pcFloorManage',
  components: {
    MarketHeader,
    MarketExtras,
    SvgIcon,
    draggable
  },
  data() {
    return {
      parentLogin: 0
    }
  },
  mounted() {
    this.isPermission()
  },
  methods: {
    isPermission() {
      API_goods.getLoginType().then(res => {
        console.log('是否是企业跳站点', res.parentLogin)
        this.parentLogin = res.parentLogin
        API_order.getShopExtAuth().then(res => {
          this.$store.getters.permission_routers.forEach(item => {
            if (item.path === '/combo-card-shop/shop-goods') {
              // 关闭菜单
              item.children.forEach((value, index) => {
                if (value.path === 'strong-push') {
                  item.children.splice(index, 1)
                }
              })
              item.children.forEach((value, index) => {
                if (value.path === 'intelligence') {
                  item.children.splice(index, 1)
                }
              })
            }
          })
          if (
            res.force_exchange_goods_open === 'OPEN' &&
            this.parentLogin === 1
          ) {
            this.$store.getters.permission_routers.forEach(item => {
              console.log(item.path)
              if (item.path === '/combo-card-shop/shop-goods') {
                // 开启菜单
                item.children.push(
                  {
                    path: `strong-push`,
                    component: () =>
                      import(`@/views/combo-card-shop/goods/strongPush.vue`),
                    name: `strongPushComboCard`,
                    meta: { title: `strongPushComboCard` }
                  },
                  {
                    path: `intelligence`,
                    component: () =>
                      import(`@/views/combo-card-shop/goods/intelligence.vue`),
                    name: `intelligenceComboCard`,
                    meta: { title: `intelligenceComboCard` }
                  }
                )
              }
            })
          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
.market-page {
}
</style>
