/**
 * Created by Andste on 2018/5/18.
 */

import tpl_4 from './tpl-4'
import tpl_5 from './tpl-5'
import tpl_6 from './tpl-6'
import tpl_7 from './tpl-7'
import tpl_10 from './tpl-10'

export default {
	4: tpl_4,
	5: tpl_5,
	6: tpl_6,
	7: tpl_7,
	10: tpl_10,
}
