/**
 * Created by Andste on 2018/5/17.
 */

import tpl_1 from './tpl-1'
import tpl_2 from './tpl-2'
import tpl_3 from './tpl-3'
import tpl_8 from './tpl-8'
import tpl_9 from './tpl-9'
import tpl_11 from './tpl-11'
import tpl_12 from './tpl-12'

export default {
  1: tpl_1,
  2: tpl_2,
  3: tpl_3,
  8: tpl_8,
  9: tpl_9,
  11: tpl_11,
  12: tpl_12,
}
